.animated-image {
  border-radius: 50%;
  border: 2px solid #062f7f;
  transition: border-color 0.3s;

  // padding: 1%;
  &.blink {
    .animated-image {
      padding: 10%;
    }
    animation: blinkAnimation 1.5s infinite;
  }
}

@keyframes blinkAnimation {
  0% {
    border-color: #062f7f;
  }

  50% {
    border-color: transparent;
  }

  100% {
    border-color: #062f7f;
  }
}

.name-overlay {
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  color: #000;
}
/* AudioPlayer.css */

/* Styling the progress bar */
.react-audio-player .react-audio-player-progress-container {
  background-color: 'red';
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.react-audio-player .react-audio-player-progress-played {
  background-color: #ff0000;
  height: 100%;
  border-radius: 5px;
}

.react-audio-player .react-audio-player-progress-remaining {
  background-color: #ddd;
  height: 100%;
  border-radius: 5px;
}

.StickyNote {
  background-color: #ffffff;
  /* width: 300px; */
  padding: 20px;
  margin: 10px;
  box-shadow:  0px 8px 9px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* .StickyNoteTitle {
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
  border: none;
  font-size: 18px;
} */

.StickyNoteContent {
  width: 100%;
  height: 250px;
  border: none;
}

//
// Custom checkbox
//

.custom-checkbox {
    .custom-control-input~.custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~.custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }

                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~.custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}

.otp-input {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid rgb(252, 201, 224);
    margin-bottom: 15px;
    margin-left: -5px;
}